import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { MergeTagPayload, TagModel } from '@models/tag';
import { TagFulltextSearchResultModel } from '@models/tagfulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class TagActions {
    static ADD_TAG = 'ADD_TAG';
    static ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
    static ADD_TAG_FAILED = 'ADD_TAG_FAILED';
    static UPDATE_TAG = 'UPDATE_TAG';
    static DELETE_TAG = 'DELETE_TAG';
    static LOAD_TAG = 'LOAD_TAG';
    static SEARCH_TAG = 'SEARCH_TAG';
    static MERGE_TAG = 'MERGE_TAG';
    static SEARCH_TAG_SUCCESS = 'SEARCH_TAG_SUCCESS';
    static SEARCH_TAG_FAILED = 'SEARCH_TAG_FAILED';
    static UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
    static UPDATE_TAG_FAILED = 'UPDATE_TAG_FAILED';
    static DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
    static DELETE_TAG_FAILED = 'DELETE_TAG_FAILED';
    static MERGE_TAG_SUCCESS = 'MERGE_TAG_SUCCESS';
    static MERGE_TAG_FAILED = 'MERGE_TAG_FAILED';
    static searchQuery = '';
    static searchedTotal = 0;
    static foundRows: any = [];

    constructor(private store: Store<any>) { }

    addTag(tag: TagModel): ActionWithPayload {
        return {
            type: TagActions.ADD_TAG,
            payload: tag
        };
    }

    updateTag(tag: TagModel): ActionWithPayload {
        return {
            type: TagActions.UPDATE_TAG,
            payload: tag
        };
    }

    mergeTag(payload: MergeTagPayload): ActionWithPayload {
        return {
            type: TagActions.MERGE_TAG,
            payload: payload
        };
    }

    mergeTagSuccess(payload: any): ActionWithPayload {
        return {
            type: TagActions.MERGE_TAG_SUCCESS,
            payload: payload
        };
    }

    mergeTagFailed(payload: any): ActionWithPayload {
        return {
            type: TagActions.MERGE_TAG_FAILED,
            payload: payload
        };
    }

    deleteTag(tag: TagModel): ActionWithPayload {
        return {
            type: TagActions.DELETE_TAG,
            payload: tag
        };
    }

    loadTag(tag: TagModel): ActionWithPayload {
        return {
            type: TagActions.LOAD_TAG,
            payload: tag
        };
    }

    searchTag(query: string, bookmark: any = null): ActionWithPayload {
        if (query !== TagActions.searchQuery) {
            TagActions.searchQuery = query;
            TagActions.searchedTotal = 0;
            TagActions.foundRows = [];
        }
        return {
            type: TagActions.SEARCH_TAG,
            payload: query,
            bookmark: bookmark
        };
    }

    searchTagSuccess(searchResult: TagFulltextSearchResultModel): ActionWithPayload {
        if (TagActions.searchedTotal < searchResult.total) {
            TagActions.searchedTotal += searchResult.data.length;
            this.store.dispatch(this.searchTag(TagActions.searchQuery, searchResult.bookmark));
        } else {
            TagActions.searchedTotal = 0;
            TagActions.searchQuery = '';
        }
        if (searchResult.data != null && searchResult.data.length > 0) {
            for (const row of searchResult.data) {
                TagActions.foundRows.push(row);
            }
        }
        searchResult.data = TagActions.foundRows;
        return {
            type: TagActions.SEARCH_TAG_SUCCESS,
            payload: searchResult
        };
    };

    searchTagFailed(error: Object): ActionWithPayload {
        return {
            type: TagActions.SEARCH_TAG_FAILED,
            payload: error
        };
    };

    addTagSuccess(response: any): ActionWithPayload {
        return {
            type: TagActions.ADD_TAG_SUCCESS,
            payload: response
        };
    };

    addTagFailed(error: Object): ActionWithPayload {
        return {
            type: TagActions.ADD_TAG_FAILED,
            payload: error
        };
    };

    updateTagSuccess(response: any): ActionWithPayload {
        return {
            type: TagActions.UPDATE_TAG_SUCCESS,
            payload: response
        };
    };

    updateTagFailed(error: Object): ActionWithPayload {
        return {
            type: TagActions.UPDATE_TAG_FAILED,
            payload: error
        };
    };

    deleteTagSuccess(response: any): ActionWithPayload {
        return {
            type: TagActions.DELETE_TAG_SUCCESS,
            payload: response
        };
    };

    deleteTagFailed(error: Object): ActionWithPayload {
        return {
            type: TagActions.DELETE_TAG_FAILED,
            payload: error
        };
    };
}
