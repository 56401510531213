import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NotificationService } from '@services/notification.service';
import { AppState } from '@services/appstate.service';
import { ImageGraphqlService } from '@services/image-graphql.service';
import { ImageActions } from '@actions/image.actions';
import { getCropResolutionEntities } from '../reducers';

import * as Ably from 'ably';

@Injectable()
export class AblyService {
    client: any;
    channel: any;
    cropResolutions$!: Observable<any>;
    url!: string;
    path!: string;
    busy = false;
    deletedImages = 0;
    processedDeletedImages = 0;

    constructor(
        private notificationService: NotificationService,
        private imageGraphqlService: ImageGraphqlService,
        private store: Store<AppState>,
        private imageActions: ImageActions
    ) { }

    start() {
        let metaDataSelector = 'https://mediaspirit.io/app_metadata';
        let profile = JSON.parse(localStorage.getItem('profile'));
        if (profile != null && profile[metaDataSelector] != null) {
            let ablySettings = profile[metaDataSelector];

            this.client = new Ably.Realtime({
                key: 'tHxJkQ.uNQyLA:g6IT8JHqc8CHJ4XK'
            });
            this.channel = this.client.channels.get(ablySettings.env);
            // this.pubnubService.init({
            //     subscribeKey: 'sub-c-2d39a3d4-4386-11e7-b66e-0619f8945a4f',
            //     authKey: ablySettings.ablyToken,
            //     uuid: profile.env // used to be auth0 id(profile.sub), but is now the client id(profile.env)(for example executive);
            // });

            this.cropResolutions$ = this.store.pipe(getCropResolutionEntities());

            this.cropResolutions$.subscribe(x => {
                for (let property in x) {
                    if (x[property].url != null && x[property].path != null && x[property].path.indexOf('square') === 0) {
                        this.url = x[property].url.replace('http://', 'https://s3-eu-west-1.amazonaws.com/');
                        this.path = x[property].path;
                        if (x[property].path.indexOf('square/xxs') === 0 || x[property].path.indexOf('square/md') === 0) {
                            break;
                        }
                    }
                }
            });

            // this.pubnubService.subscribe({ channels: [ablySettings.env] });
            this.channel.subscribe((obj: any) => {
                if (obj.data != null) {
                    console.log(obj)
                    if (obj.name === 'imageTagAdded') {
                        this.notificationService.success('Tag added to image');
                        return;
                    }
                    if (obj.name === 'tagBulkAdded') {
                        const imageIds = JSON.parse(obj.data).imageIds || null;
                        if (imageIds != null && imageIds.length > 0) {
                            for (const imageId of imageIds) {
                                this.imageGraphqlService.retrieveImage(imageId).subscribe((image: any) => {
                                    if (image.data.image.success === true) {
                                        this.store.dispatch(this.imageActions.updateImageSuccess(image.data.image.image));
                                    }
                                });
                                this.notificationService.success('Tag added to image');
                            }
                        }
                        return;
                    }
                    if (obj.name === 'imageTagDeleted') {
                        this.notificationService.success('Tag deleted from image');
                        return;
                    }
                    if (obj.name === 'imageUpdated') {
                        const imageId = JSON.parse(obj.data).imageId || null;
                        if (imageId != null) {
                            this.store.dispatch(this.imageActions.updateImageSuccess({ empty: true }));
                            this.store.dispatch(this.imageActions.updateImageSuccess({ empty: false }));
                            this.notificationService.success('Updated image');
                        }
                        return;
                    }
                    if (obj.name === 'imageAIInterpreted') {
                        this.notificationService.success(`Cognitive recognition of "${JSON.parse(obj.data).cleanUrl.replace('/', '')}" successful`);
                        const imageId = JSON.parse(obj.data).imageId || null;
                        if (imageId != null) {
                            this.store.dispatch(this.imageActions.updateImageSuccess({ empty: true }));
                            this.store.dispatch(this.imageActions.updateImageSuccess({ empty: false }));
                        }
                        return;
                    }
                    if (obj.name === 'mediaUploaded') {
                        this.notificationService.success(`Image "${JSON.parse(obj.data).cleanUrl.replace('/', '')}" uploaded successfully`);
                        return;
                    }
                    if (obj.name === 'imageCropped') {
                        const imageId = JSON.parse(obj.data).imageId || null;
                        if (imageId != null) {
                            this.store.dispatch(this.imageActions.updateImageSuccess({ empty: true }));
                            this.store.dispatch(this.imageActions.updateImageSuccess({ empty: false }));
                            this.notificationService.success(`Image "${JSON.parse(obj.data).cleanUrl.replace('/', '')}" is cropped succesfully`);
                        }
                        return;
                    }
                    if (obj.name === 'imageDeleted') {
                        this.notificationService.success('Image deleted successfully');
                        return;
                    }
                    if (obj.name === 'tagMerged') {
                        const data = JSON.parse(obj.data);
                        this.notificationService.success(`'${data.sourceId}' succesfully merged into '${data.targetId}'`);
                        this.notificationService.info(`Images will be updated automatically in the background. Might take a while depending on the amount of images.`);
                        return;
                    }
                }
            });
        }
    }

    deletedAllImagesProcessed() {
        this.notificationService.success('Successfully deleted all images');
    }
}
